import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import axios from 'axios'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import {domain} from './config/config'

Vue.config.productionTip = false
Vue.use(ElementUI);

axios.defaults.baseURL = domain;

axios.interceptors.response.use((response) => {
  return response;
}, error => {
  return Promise.reject(error);
});

Vue.prototype.$http = axios

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
