import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/index.vue'),
        meta: {title: 'home'},
    },
     {
        path: '/redeem/:id',
        name: 'redeem',
        component: () => import('@/views/redeem.vue'),
        meta: {title: 'redeem'},
    }
];

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    routes
})

export default router
